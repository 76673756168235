import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import open_dispute from "./modules/open_dispute";
import incoming_dispute from "./modules/incoming_dispute";
import closed_dispute from "./modules/Closed/closed_dispute";
import closed_fulfilled_dispute from "./modules/Closed/closed_fulfilled_dispute";
import closed_not_fulfilled_dispute from "./modules/Closed/closed_not_fulfilled_dispute";
import closed_expired_dispute from "./modules/Closed/closed_expired_dispute";
import closed_accepted_dispute from "./modules/Closed/closed_accepted_dispute";
import closed_rejected_dispute from "./modules/Closed/closed_rejected_dispute";
import underreview_dispute from "./modules/underreview_dispute";
import inprogress_dispute from "./modules/inprogress_dispute";
import menu from "./modules/menu";

Vue.use(Vuex);

const debug = "production" !== process.env.NODE_ENV;

export default new Vuex.Store({
  modules: {
    auth: auth,
    open_dispute: open_dispute,
    incoming_dispute: incoming_dispute,
    closed_dispute: closed_dispute,
    closed_expired_dispute: closed_expired_dispute,
    closed_fulfilled_dispute: closed_fulfilled_dispute,
    closed_not_fulfilled_dispute: closed_not_fulfilled_dispute,
    closed_accepted_dispute: closed_accepted_dispute,
    closed_rejected_dispute: closed_rejected_dispute,
    underreview_dispute: underreview_dispute,
    inprogress_dispute: inprogress_dispute,
    menu: menu,
  },
  strict: debug,
});
