export default [
  {
    path: "/disputes/incoming",
    name: "incoming_disputes",
    meta: {
      activeTab: 2,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "incoming_disputes",
          title: "Incoming disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/IncomingDisputes"),
  },
  {
    path: "/disputes/open",
    name: "open_disputes",
    meta: {
      activeTab: 3,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "open_disputes",
          title: "Open disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/OpenDisputes"),
  },
  {
    path: "/disputes/in-progress",
    name: "in_progress_disputes",
    meta: {
      activeTab: 10,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "in_progress_disputes",
          title: "In progress disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/InProgress"),
  },
  {
    path: "/disputes/under-review",
    name: "under_review_disputes",
    meta: {
      activeTab: 11,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "under_review_disputes",
          title: "Under review disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/UnderReview"),
  },
  {
    path: "/disputes/closed",
    name: "closed_disputes",
    meta: {
      activeTab: 4,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "closed_disputes",
          title: "Closed disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/Closed/ClosedDisputes"),
  },
  {
    path: "/disputes/closed/expired",
    name: "closed_expired_disputes",
    meta: {
      activeTab: 5,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "closed_disputes",
          title: "Closed disputes",
        },
        {
          path: "closed_expired_disputes",
          title: "Expired disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/Closed/ClosedExpired"),
  },
  {
    path: "/disputes/closed/fulfilled",
    name: "closed_fulfilled_dispute",
    meta: {
      activeTab: 6,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "closed_disputes",
          title: "Closed disputes",
        },
        {
          path: "closed_fulfilled_dispute",
          title: "Fulfilled disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/Closed/ClosedFulfilled"),
  },
  {
    path: "/disputes/closed/not-fulfilled",
    name: "closed_not_fulfilled_dispute",
    meta: {
      activeTab: 7,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "closed_disputes",
          title: "Closed disputes",
        },
        {
          path: "closed_not_fulfilled_dispute",
          title: "Not-fulfilled disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/Closed/ClosedNotFulfilled"),
  },
  {
    path: "/disputes/closed/accepted",
    name: "closed_accepted_dispute",
    meta: {
      activeTab: 8,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "closed_disputes",
          title: "Closed disputes",
        },
        {
          path: "closed_accepted_dispute",
          title: "Accepted disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/Closed/ClosedAccepted"),
  },
  {
    path: "/disputes/closed/rejected",
    name: "closed_rejected_dispute",
    meta: {
      activeTab: 9,
      layout: "Default",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "closed_disputes",
          title: "Closed disputes",
        },
        {
          path: "closed_rejected_dispute",
          title: "Rejected disputes",
        },
      ],
    },
    component: () => import("@/pages/disputes/Closed/ClosedRejected"),
  },
  {
    path: "/disputes/view/:id",
    name: "view_dispute",
    meta: {
      layout: "Grey",
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
        {
          path: "view_dispute",
          title: "Dispute",
        },
      ],
    },
    component: () => import("@/pages/disputes/ViewDispute"),
  },
];
