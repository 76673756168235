import repository from "../repository";

export default {
  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  transition(disputeId, transition) {
    return repository.get(
      "dispute_processing/" + disputeId + "/transition/" + transition
    );
  },
  /**
   * @param disputeId
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  markDisputeAsReviewed(disputeId) {
    return repository.get("mark_dispute_as_reviewed/" + disputeId);
  },
  /**
   * @param disputeId
   *
   * @returns {Promise<AxiosResponse<T>>}
   */
  markDisputeAsNotReviewed(disputeId) {
    return repository.get("mark_dispute_as_not_reviewed/" + disputeId);
  },

  /**
   * @param id
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAvailableTransitions(id) {
    return repository.get(
      "dispute_processing/" + id + "/available_transitions"
    );
  },
};
