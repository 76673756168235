import Vue from "vue";
import store from "./store";
import router from "./router";
import App from "./App.vue";
import "@/assets/fonts/WeblySleekUI/stylesheet.css";
import "@/themes/tribe/custom.css";
import { CanDirective } from "./directives/can";
import { notification } from "ant-design-vue";
import moment from "moment-timezone";
Vue.prototype.$notification = notification;
moment.tz.setDefault("Europe/London");

Vue.mixin({
  created: function () {
    store.commit("auth/AUTH_RELOAD");
  },
});

Vue.directive("can", CanDirective);
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
