import repository from "../repository";
import { print } from "graphql";
import transactionsList from "../graphql/queries/transactionsList.graphql";

const resource = "graphql";
export default {
  /**
   * @returns Promise<AxiosResponse<any>>
   */
  disputeRelatedTransactions(disputeId) {
    return repository.post(resource, {
      query: print(transactionsList),
      variables: {
        disputeId: disputeId,
      },
    });
  },
};
