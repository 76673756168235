import {
  DISPUTE_SET_PAGINATION,
  DISPUTE_SET_FILTERS,
  DISPUTE_SET_SORTING,
  EVENT_DISPUTE_FILTER_SUBMIT,
  DISPUTE_RESET_FILTERS,
} from "../mutations/dispute";
import moment from "moment";

export default {
  namespaced: true,
  getters: {
    getAll: (state) => {
      return {
        ...state.paginator,
        ...state.filters,
        ...state.sorting,
      };
    },
  },
  mutations: {
    [DISPUTE_SET_PAGINATION](state, data) {
      state.paginator = { ...state.paginator, ...data };
    },
    [DISPUTE_SET_FILTERS](state, data) {
      state.filters = {
        ...state.filters,
        ...data,
      };
    },
    [DISPUTE_SET_SORTING](state, data) {
      state.sorting = { ...state.sorting, ...data };
    },
    [EVENT_DISPUTE_FILTER_SUBMIT]() {},
  },
  actions: {
    async setPagination({ commit }, { ...params }) {
      commit(DISPUTE_SET_PAGINATION, params);
    },
    async setFilters({ commit }, { ...params }) {
      commit(DISPUTE_SET_FILTERS, params);
    },
    async resetFilters({ commit }) {
      commit(DISPUTE_RESET_FILTERS);
    },
    async setSorting({ commit }, { ...params }) {
      commit(DISPUTE_SET_SORTING, params);
    },
    async submitFilterForm({ commit }, { ...params }) {
      commit(EVENT_DISPUTE_FILTER_SUBMIT, params);
    },
  },
  defaultFilters: {
    openDateRange: [
      moment(new Date().setDate(new Date().getDate() - 30)),
      moment(new Date()),
    ],
    dueDateRange: [
      moment(new Date()),
      moment(new Date().setDate(new Date().getDate() + 7)),
    ],
  },
};
