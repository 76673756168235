import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_RELOAD } from "../mutations/auth";
import { repositoryFactory } from "../../api/repositoryFactory";

const authRepository = repositoryFactory.get("auth");
const userRepository = repositoryFactory.get("user");

export default {
  namespaced: true,
  state: {
    token: false,
    userId: null,
    role: "",
    username: "",
    permissions: [],
  },
  getters: {
    isLogged: () => {
      return !!localStorage.getItem("token");
    },
    getToken: (state) => {
      return state.token;
    },
    getRole: (state) => {
      return state.role;
    },
    canAccess: (state) => (permission) => {
      return state.permissions.indexOf(permission) > -1; // exists in list
    },
    getUsername: (state) => {
      return state.username;
    },
    getUserId: (state) => {
      return parseInt(state.userId); // exists in list
    },
  },
  mutations: {
    [AUTH_LOGIN](state, data) {
      state.token = data.token;

      state.permissions = [];

      data.user.userRole.permissions.edges.forEach((item) => {
        state.permissions.push(item.node.name);
      });

      state.role = data.user.userRole.name;
      state.username = data.user.username;

      localStorage.setItem("role", data.user.userRole.name);
      localStorage.setItem("username", data.user.username);
      localStorage.setItem("token", state.token);
      localStorage.setItem("userId", data.user._id);
      localStorage.setItem("permissions", JSON.stringify(state.permissions));
    },
    [AUTH_LOGOUT](state) {
      state.token = false;
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("userId");
      localStorage.removeItem("role");
      localStorage.removeItem("username");
    },
    [AUTH_RELOAD](state) {
      if (!state.permissions || state.permissions.length < 1) {
        state.permissions = JSON.parse(localStorage.getItem("permissions"));
      }
      if (!state.token) {
        state.token = localStorage.getItem("token");
      }
      if (!state.userId) {
        state.userId = localStorage.getItem("userId");
      }
      if (!state.role) {
        state.role = localStorage.getItem("role");
      }
      if (!state.username) {
        state.username = localStorage.getItem("username");
      }
    },
  },
  actions: {
    /**
     * @param commit
     * @param string username
     * @param string password
     * @returns {Promise<boolean>}
     */
    async login({ commit }, { username, password }) {
      const login = await authRepository.login(username, password);
      const tokenData = repositoryFactory.data(login);

      localStorage.setItem("token", login.data.token);

      const user = await userRepository.getInfo(tokenData.userId);
      const userData = repositoryFactory.data(user);
      commit(AUTH_LOGIN, { token: login.data.token, user: userData.user });
    },

    /**
     * @param commit
     * @param token
     * @param userId
     * @returns {Promise<void>}
     */
    async autoLogin({ commit }, { token, userId }) {
      localStorage.setItem("token", token);
      const user = await userRepository.getInfo(userId);
      const userData = repositoryFactory.data(user);
      commit(AUTH_LOGIN, { token: token, user: userData.user });
    },
    logout({ commit }) {
      commit(AUTH_LOGOUT);
    },
  },
};
