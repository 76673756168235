<template>
  <div>
    <a-menu
      theme="dark"
      mode="horizontal"
      :selected-keys="activeTab"
      :style="{ lineHeight: '64px' }"
      @select="selected"
    >
      <a-menu-item key="1">
        <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
      </a-menu-item>
      <a-menu-item key="2" v-can="'dispute.dispute.submit_to_merchant'">
        <router-link :to="{ name: 'incoming_disputes' }">Incoming</router-link>
      </a-menu-item>
      <a-menu-item key="3" v-can="'dispute.dispute.access'">
        <router-link :to="{ name: 'open_disputes' }">Open</router-link>
      </a-menu-item>

      <a-sub-menu key="sub1">
        <span slot="title" class="submenu-title-wrapper"> Closed </span>
        <a-menu-item key="4" v-can="'dispute.dispute.access'">
          <router-link :to="{ name: 'closed_disputes' }">All</router-link>
        </a-menu-item>
        <a-menu-item key="5" v-can="'dispute.dispute.access'">
          <router-link :to="{ name: 'closed_expired_disputes' }"
            >Expired</router-link
          >
        </a-menu-item>
        <a-menu-item key="6" v-can="'dispute.dispute.access'">
          <router-link :to="{ name: 'closed_fulfilled_dispute' }"
            >Fulfilled</router-link
          >
        </a-menu-item>
        <a-menu-item key="7" v-can="'dispute.dispute.access'">
          <router-link :to="{ name: 'closed_not_fulfilled_dispute' }"
            >Not-fulfilled</router-link
          >
        </a-menu-item>
        <a-menu-item key="8" v-can="'dispute.dispute.access'">
          <router-link :to="{ name: 'closed_accepted_dispute' }"
            >Accepted</router-link
          >
        </a-menu-item>
        <a-menu-item key="9" v-can="'dispute.dispute.access'">
          <router-link :to="{ name: 'closed_rejected_dispute' }"
            >Rejected</router-link
          >
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="10" v-can="'dispute.dispute.access'">
        <router-link :to="{ name: 'in_progress_disputes' }"
          >In progress</router-link
        >
      </a-menu-item>

      <a-menu-item key="11" v-can="'dispute.dispute.access'">
        <router-link :to="{ name: 'under_review_disputes' }"
          >Under review</router-link
        >
      </a-menu-item>

      <a-menu-item key="12" :style="{ float: 'right' }">
        <router-link :to="{ name: 'logout' }">
          <a-icon type="logout" />Logout
        </router-link>
      </a-menu-item>

      <a-menu-item key="13" :disabled="true" :style="{ float: 'right' }">
        <a-icon type="user" />{{ username }}
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { Menu, Icon } from "ant-design-vue";

export default {
  name: "MenuLayout",
  components: {
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-sub-menu": Menu.SubMenu,
    "a-icon": Icon,
  },
  data() {
    return {
      activeTab: ["0"],
      username: "",
    };
  },
  async mounted() {
    let activeTab = await this.$store.dispatch("menu/getActiveTab");
    this.activeTab = [activeTab.toString()];
    this.username = await this.$store.getters["auth/getUsername"];
  },
  methods: {
    async selected(item) {
      await this.$store.dispatch("menu/setActiveTab", {
        activeTab: item.key,
      });
      this.activeTab = [item.key];
    },
  },
};
</script>

<style>
/*.ant-menu-submenu.ant-menu-submenu-selected {*/
/*  background: #1da57a;*/
/*}*/
.ant-menu-submenu.ant-menu-submenu-selected a {
  color: #fff;
}
.ant-menu-submenu span::selection {
  background: none;
}
</style>
