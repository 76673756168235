import repository from "../repository";
import { print } from "graphql";
import internalCommentsList from "../graphql/queries/internalCommentsList.graphql";

const resource = "graphql";

export default {
  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getList(disputeId) {
    return repository.post(resource, {
      query: print(internalCommentsList),
      variables: { disputeId: disputeId },
    });
  },
  saveComment(formData) {
    return repository.post("/dispute_internal_comments", formData);
  },
  uploadFile(formData) {
    return repository.post("/dispute_internal_comment_files/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  download(id, fileName) {
    repository
      .get("/download/internal_comment_file/" + id, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  },
  delete(id) {
    return repository.delete("dispute_internal_comments/" + id);
  },
};
