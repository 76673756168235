import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";

import "../node_modules/nprogress/nprogress.css";

Vue.use(VueRouter);

const context = require.context("./routes", true, /\.(js)$/i);
const routes = [];

context.keys().forEach(function (key) {
  routes.push(...context(key).default);
});

const router = new VueRouter({
  routes: routes,
  base: "/",
  mode: "history",
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach(() => {
  NProgress.done();
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
  if (to.name == "auto_login") {
    token = "autologin";
  }
  // active tab is set
  if (to.meta.activeTab !== undefined) {
    localStorage.setItem("activeTab", to.meta.activeTab);
  }
  if (to.name !== "login" && token === null) {
    next("/login");
  } else {
    next();
  }
});

Vue.router = router;

export default router;
