import repository from "../repository";
import { print } from "graphql";
import stateHistoriesList from "../graphql/queries/stateHistoriesList.graphql";

const resource = "graphql";

export default {
  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getList(disputeId) {
    return repository.post(resource, {
      query: print(stateHistoriesList),
      variables: {
        disputeId: parseInt(disputeId),
      },
    });
  },
};
