import repository from "../repository";
import { print } from "graphql";
import disputeDocumentsList from "../graphql/queries/disputeDocumentsList.graphql";

const resource = "graphql";

export default {
  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getList(disputeId) {
    return repository.post(resource, {
      query: print(disputeDocumentsList),
      variables: { disputeId: disputeId },
    });
  },
};
