import abstract_dispute from "./abstract_dispute";
import { DISPUTE_RESET_FILTERS } from "../mutations/dispute";

export default {
  ...abstract_dispute,
  state: {
    paginator: {
      page: 0,
      pageSize: parseInt(process.env.VUE_APP_DEFAULT_LIST_SIZE),
    },
    filters: {
      ...abstract_dispute.defaultFilters,
      disputeStatus: 1,
    },
    sorting: {},
  },
  mutations: {
    ...abstract_dispute.mutations,
    [DISPUTE_RESET_FILTERS](state) {
      state.filters = {
        ...abstract_dispute.defaultFilters,
        disputeStatus: 1,
      };
    },
  },
};
