import { MENU_ACTIVE_TAB } from "@/store/mutations/menu";

export default {
  namespaced: true,
  state: {
    activeTab: false,
  },
  getters: {},
  mutations: {
    [MENU_ACTIVE_TAB](state, data) {
      localStorage.setItem("activeTab", data.activeTab);
      state.activeTab = data.activeTab;
    },
  },
  actions: {
    async setActiveTab({ commit }, { ...val }) {
      commit(MENU_ACTIVE_TAB, { activeTab: val.activeTab });
    },
    getActiveTab() {
      return localStorage.getItem("activeTab") > 0
        ? localStorage.getItem("activeTab")
        : 1;
    },
  },
};
