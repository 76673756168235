<template>
  <a-layout id="default-layout" class="layout" style="minheight: 100vh">
    <a-layout-header>
      <div class="logo" />
      <default-menu></default-menu>
    </a-layout-header>
    <a-layout-content style="padding: 0 50px">
      <a-breadcrumb :routes="breadcrumbList" style="margin: 16px 0">
        <template slot="itemRender" slot-scope="{ route, routes, paths }">
          <span v-if="routes.indexOf(route) === routes.length - 1">
            {{ route.title }}
          </span>
          <router-link v-else :to="paths.join('/')">
            <router-link :to="{ name: route.path }">{{
              route.title
            }}</router-link>
          </router-link>
        </template>
      </a-breadcrumb>
      <div :style="{ background: '#f0f2f5', minHeight: '280px' }">
        <div class="container">
          <router-view></router-view>
        </div>
      </div>
      <a-back-top />
    </a-layout-content>
    <a-layout-footer style="text-align: center">
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} {{ footerText }}<br />
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import { Layout, Breadcrumb, BackTop } from "ant-design-vue";
import MenuLayout from "./Menu";

export default {
  name: "DefaultLayout",
  components: {
    "a-layout": Layout,
    "a-layout-header": Layout.Header,
    "a-layout-content": Layout.Content,
    "a-layout-footer": Layout.Footer,
    "a-breadcrumb": Breadcrumb,
    "default-menu": MenuLayout,
    "a-back-top": BackTop,
  },
  data() {
    return {
      breadcrumbList: this.$route.meta.breadcrumb,
      footerText: process.env.FOOTER_TEXT,
    };
  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style scoped>
thead.ant-table-thead * {
  font-weight: 700;
}
.container {
  min-height: calc(100vh - 260px);
  background: #fff;

  padding: 30px;
  padding-top: 15px;
}
</style>
