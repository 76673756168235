export default [
  {
    path: "/",
    name: "dashboard",
    meta: {
      layout: "Grey",
      activeTab: 1,
      breadcrumb: [
        {
          path: "dashboard",
          title: "🏠",
        },
      ],
    },
    component: () => import("@/pages/dashboard/IndexPage"),
  },
];
