import repository from "../repository";
import store from "@/store";

export default {
  downloadDisputeFile(id, fileName) {
    repository
      .get("/download/dispute_file/" + id, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  },
  disputesList(type, state, storeName) {
    const filters = store.getters[storeName + "/getAll"];
    const url = "/dispute_processing/export/" + type + "/" + state;

    let date = {};
    if (filters.openDateRange && filters.openDateRange.length > 0) {
      date = {
        date: {
          after: filters.openDateRange[0]
            .clone()
            .startOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
          before: filters.openDateRange[1]
            .clone()
            .endOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
        },
      };
    }

    let dueDate = {};
    if (filters.dueDateRange && filters.dueDateRange.length > 0) {
      dueDate = {
        dueDate: {
          after: filters.dueDateRange[0]
            .clone()
            .startOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
          before: filters.dueDateRange[1]
            .clone()
            .endOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
        },
      };
    }

    repository
      .post(
        url,
        { ...filters, ...date, ...dueDate },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          (state ? state.toLowerCase() : "") + "_disputes." + type
        );
        document.body.appendChild(link);
        link.click();
      });
  },
};
