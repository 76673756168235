import authRepository from "./repos/auth";
import userRepository from "./repos/user";
import disputeRepository from "./repos/dispute";
import disputeDocumentRepository from "./repos/disputeDocument";
import commentRepository from "./repos/comment";
import internalCommentRepository from "./repos/internalComment";
import downloadRepository from "./repos/download";
import stateHistoryRepository from "./repos/statehistory";
import disputeProcessingRepo from "./repos/disputeProcessing";
import transactionRepo from "./repos/transaction";

const repositories = {
  auth: authRepository,
  user: userRepository,
  dispute: disputeRepository,
  disputeDocument: disputeDocumentRepository,
  comment: commentRepository,
  internalComment: internalCommentRepository,
  download: downloadRepository,
  stateHistory: stateHistoryRepository,
  disputeProcessing: disputeProcessingRepo,
  transaction: transactionRepo,
};

export const repositoryFactory = {
  get: (name) => repositories[name],
  data: (result) => result.data.data,
};
