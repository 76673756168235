import repository from "../repository";
import { print } from "graphql";
import store from "../../store";
import disputesList from "../graphql/queries/disputesList.graphql";
import disputesListFilters from "../graphql/queries/disputesListFilters.graphql";
import disputeInfo from "../graphql/queries/disputeInfo.graphql";
import changeMerchantStatus from "../graphql/mutations/changeMerchantStatus.graphql";
import changeAcquirerStatus from "../graphql/mutations/changeAcquirerStatus.graphql";

const resource = "graphql";

export default {
  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getLastUpdatedList() {
    return repository.get("/disputes/get-last-updated");
  },

  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getList(state, storeName) {
    const variables = store.getters[storeName + "/getAll"];
    if (variables.page === 0) {
      delete variables.page;
    }

    if (variables.closeType === undefined) {
      delete variables.closeType;
    }

    if (!variables.page || variables.page == 0) {
      delete variables.page;
    }

    let date = {};
    if (variables.openDateRange && variables.openDateRange.length > 0) {
      date = {
        date: {
          after: variables.openDateRange[0]
            .clone()
            .startOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
          before: variables.openDateRange[1]
            .clone()
            .endOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
        },
      };
    }

    let dueDate = {};
    if (variables.dueDateRange && variables.dueDateRange.length > 0) {
      dueDate = {
        dueDate: {
          after: variables.dueDateRange[0]
            .clone()
            .startOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
          before: variables.dueDateRange[1]
            .clone()
            .endOf("day")
            .format(process.env.VUE_APP_DATE_TIME_FORMAT),
        },
      };
    }

    // do not filter status when when all disputes filter is selected
    if (variables.disputeStatus != 1) {
      delete variables.disputeStatus;
    }

    let filterState = { state: state };
    if (state === "Incoming") {
      filterState = {
        stateList: ["OPEN", "WAIT_ACQ_ACTION", "WAIT_MERCHANT_ACTION"],
        disputeStatus: 1,
      };
    }

    const sortMap = {
      date: "date",
      caseNumber: "caseNumber",
      mcc: "mcc",
      dueDate: "dueDate",
      disputeAmount: "amount",
      cycle: "cycle",
      cardScheme: "transaction_cardScheme_name",
      transactionId: "transaction_externalId",
      transactionAmount: "transaction_amount",
      transactionCurrency: "transaction_currency_code",
      merchantId: "company_externalId",
      merchantName: "company_title",
      tenant: "tenant_title",
      reasonType: "reason_type",
      reasonCode: "reason_code",
      disputeCurrency: "currency_code",
      daysLeft: "dueDate",
    };

    let combinedVariables = {
      ...variables,
      ...filterState,
      ...dueDate,
      ...date,
      ...(variables.page
        ? {
            page: btoa(
              variables.page * parseInt(process.env.VUE_APP_DEFAULT_LIST_SIZE) -
                1
            ),
          }
        : {}),
    };

    if (
      combinedVariables.sortKey &&
      combinedVariables.sortDirection &&
      sortMap[combinedVariables.sortKey]
    ) {
      combinedVariables["order"] = {
        [sortMap[combinedVariables.sortKey]]: combinedVariables.sortDirection,
      };
    }

    for (let propName in combinedVariables) {
      if (
        combinedVariables[propName] === null ||
        combinedVariables[propName] === undefined
      ) {
        delete combinedVariables[propName];
      }
    }

    if (combinedVariables.state === "CLOSED") {
      delete combinedVariables.state;
      combinedVariables.stateList = ["CLOSED", "EXPIRED"];
    }

    return repository.post(resource, {
      query: print(disputesList),
      variables: combinedVariables,
    });
  },

  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getFilters() {
    return repository.post(resource, {
      query: print(disputesListFilters),
    });
  },

  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getDispute(id) {
    return repository.post(resource, {
      query: print(disputeInfo),
      variables: { disputeId: "/api/disputes/" + id },
    });
  },

  /**
   * @param formData
   * @returns {Promise<AxiosResponse<T>>}
   */
  uploadDocument(formData) {
    return repository.post("/upload_files", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  /**
   * @param id
   * @param statusId
   * @returns {Promise<AxiosResponse<T>>}
   */
  changeMerchantStatus(id, statusId) {
    return repository.post(resource, {
      query: print(changeMerchantStatus),
      variables: {
        disputeId: "/api/disputes/" + id,
        statusId: statusId.toString(),
      },
    });
  },

  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getFiles(disputeId) {
    return repository.get("/file/get-dispute-list/" + disputeId);
  },

  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getPresavedComments(disputeId) {
    return repository.get("/presaved-comment/" + disputeId);
  },

  /**
   * @returns Promise<AxiosResponse<any>>
   */
  saveComment(disputeId, comment, commentType) {
    return repository.post("/presaved-comment/" + disputeId, {
      commentType: commentType,
      comment: comment,
    });
  },

  /**
   * @returns Promise<AxiosResponse<any>>
   */
  deleteFile(fileId, fileType) {
    return repository.get("/file/delete/" + fileType + "/" + fileId);
  },

  /**
   * @param id
   * @param statusId
   * @returns {Promise<AxiosResponse<T>>}
   */
  changeAcquirerStatus(id, statusId) {
    return repository.post(resource, {
      query: print(changeAcquirerStatus),
      variables: {
        disputeId: "/api/disputes/" + id,
        statusId: statusId.toString(),
      },
    });
  },

  /**
   * @param id
   * @param statusId
   * @returns {Promise<AxiosResponse<T>>}
   */
  submitToMerchant(id) {
    return repository.post("/dispute_processing/" + id + "/submit-to-merchant");
  },
};
