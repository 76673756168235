import repository from "../repository";

const resource = "/auth_token";

export default {
  /**
   * @param {string} username
   * @param {string} password
   */
  login(username, password) {
    return repository.post(resource, {
      username: username,
      password: password,
    });
  },
};
