import repository from "../repository";
import { print } from "graphql";
import userInfo from "../graphql/queries/userInfo.graphql";

const resource = "graphql";

export default {
  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getInfo(userId) {
    return repository.post(resource, {
      query: print(userInfo),
      variables: { userId: "/api/users/" + userId },
    });
  },
};
