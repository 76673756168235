import store from "../store";

export const CanDirective = {
  bind(el, binding) {
    if (false === store.getters["auth/canAccess"](binding.value)) {
      el.innerText = "";
      el.style.display = "none";
    }
  },
};
