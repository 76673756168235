<template>
  <layout :is="layout">
    <router-view />
  </layout>
</template>

<script>
import DefaultLayout from "./layouts/Default";
import LoginLayout from "./layouts/Login";
import PreloadLayout from "./layouts/Preload";
import GreyLayout from "./layouts/Grey";
import PlainLayout from "./layouts/Plain";

export default {
  components: {
    PreloadLayout: PreloadLayout,
    LoginLayout: LoginLayout,
    DefaultLayout: DefaultLayout,
    GreyLayout: GreyLayout,
    PlainLayout: PlainLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "Preload") + "Layout";
    },
  },
};
</script>
