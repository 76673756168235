import axios from "axios";
import { notification } from "ant-design-vue";
import store from "@/store/index";
import router from "@/router";

const baseDomain = process.env.VUE_APP_API_URL;

const repository = axios.create({
  baseURL: baseDomain,
});

repository.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

repository.interceptors.response.use(
  async (response) => {
    if (response.data.errors) {
      let errorMessage =
        undefined !== response.data.errors[0].message
          ? response.data.errors[0].message
          : "Request to server failed!";
      await notification.error({
        message: "Error",
        description: errorMessage,
      });
      return Promise.reject(response);
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  async (error) => {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errorMessage
    ) {
      await notification.error({
        message: "Error",
        description: error.response.data.errorMessage,
      });
    }
    // Any status codes that falls OUTSIDE the range of 2xx cause this function to trigger
    if (401 === error.response.status && store.getters["auth/isLogged"]) {
      await store.dispatch("auth/logout");
      await router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default repository;
