import repository from "../repository";
import { print } from "graphql";
import commentsList from "../graphql/queries/commentsList.graphql";

const resource = "graphql";

export default {
  /**
   * @returns Promise<AxiosResponse<any>>
   */
  getList(disputeId) {
    return repository.post(resource, {
      query: print(commentsList),
      variables: { disputeId: disputeId },
    });
  },
  saveComment(formData) {
    return repository.post("/dispute_comments", formData);
  },
  uploadFile(formData) {
    return repository.post("/dispute_comment_files/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  download(id, fileName) {
    repository
      .get("/download/comment_file/" + id, { responseType: "blob" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  },
};
